import Box, { type BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { SwiperSlide } from 'swiper/react'
import Carousel from 'src/components/shared/Carousel'

type Company = {
    name: string
    imgSrc: string
}
type Props = BoxProps & {
    title?: string
    companies?: Company[]
    slideWidth?: number
    duration?: number
}

const defaultCompanies: Company[] = [
    {
        name: 'icloud',
        imgSrc: '/images/new-theme/companies/icloud-gray.png'
    },
    {
        name: 'google',
        imgSrc: '/images/new-theme/companies/google-gray.png'
    },
    {
        name: 'visa',
        imgSrc: '/images/new-theme/companies/visa-gray.png'
    },
    {
        name: 'facebook',
        imgSrc: '/images/new-theme/companies/facebook-gray.png'
    },
    {
        name: 'adobe',
        imgSrc: '/images/new-theme/companies/adobe-gray.png'
    },
    {
        name: 'amazon',
        imgSrc: '/images/new-theme/companies/amazon-gray.png'
    },
    {
        name: 'hubspot',
        imgSrc: '/images/new-theme/companies/hubspot-gray.png'
    },
    {
        name: 'quora',
        imgSrc: '/images/new-theme/companies/quora-gray.png'
    },
    {
        name: 'github',
        imgSrc: '/images/new-theme/companies/github-gray.png'
    }
]

export default function CompaniesSlider({
    title = 'Join the world’s top companies using CUF',
    companies = defaultCompanies,
    slideWidth = 70,
    duration = 10 * 750,
    ...rest
}: Props) {
    return (
        <Box position='relative' {...rest}>
            <Typography mb={8} component='p' typography='p' variant='newBodySm' align='center' color='#fff'>
                {title}
            </Typography>
            <Carousel
                loop
                rewind={false}
                draggable={false}
                slidesPerView={'auto'}
                spaceBetween={40}
                centeredSlides={false}
                speed={duration}
                autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: false,
                    reverseDirection: false,
                    stopOnLastSlide: false,
                    waitForTransition: true
                }}
                wrapperClass='items-center ease-linear pointer-events-none'
            >
                {companies.map((company) => (
                    <SwiperSlide key={company.name} style={{ width: `${slideWidth}px` }}>
                        <img
                            src={company.imgSrc}
                            alt={company.name}
                            style={{ width: '100%', filter: 'grayscale(1)' }}
                        />
                    </SwiperSlide>
                ))}
            </Carousel>
        </Box>
    )
}
